import { styled } from 'src/styles/stitches.config'

const Button = styled('button', {
    display: 'inline-block',
    border: 'solid 1px $black',
    width: 'auto',
    padding: '$xxs $xs',

    variants: {
        variant: {
            white: {
                border: 'solid 1px $white',
                color: '$white',
            },

            whiteFill: {
                color: '$black',
                backgroundColor: '$white',
                border: 'solid 1px $white',
            },
            blue: {
                border: 'solid 1px $green',
                color: '$green',
            },
            blueFill: {
                border: 'solid 1px $green',
                color: '$white',
                backgroundColor: '$green',
            },
        },
    },
})

export default Button
