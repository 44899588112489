import { Link } from 'react-router-dom'
import Image from 'src/components/atoms/Image/Image'
import LinkButton from 'src/components/atoms/LinkButton/LinkButton'
import Text from 'src/components/atoms/Text/Text'
import Container from 'src/components/_layout/Container/Container'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import { CmsImage, CmsLink } from 'src/types'
import { StyledFeaturedContent } from './FeaturedContent.style'

type FeaturedContentProps = {
    items: Array<{
        image: CmsImage
        title: string
        description: string
        extra: string
        link: CmsLink
    }>
}

const FeaturedContent: React.FC<FeaturedContentProps> = ({ items }) => {
    return (
        <Container>
            <FlexWrapper
                as={Padder}
                justify={{ '@initial': 'start', '@md': 'between' }}
                direction={{ '@initial': 'column', '@md': 'row' }}
                top={{ '@mdDown': 'lg' }}
            >
                {items.map(
                    ({ image, title, description, extra, link }, index) => (
                        <StyledFeaturedContent as={Padder} top="md">
                            <Image src={image.url} />
                            <Text
                                textVariant="heading2Bis"
                                textTransform="uppercase"
                            >
                                {title}
                            </Text>
                            <Text
                                textVariant="base"
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}
                                css={{ '@md': { width: '80%' } }}
                            />
                            {extra && (
                                <Text
                                    textVariant="base2"
                                    dangerouslySetInnerHTML={{
                                        __html: extra,
                                    }}
                                    css={{
                                        '@md': { width: '80%' },
                                        columns: 2,
                                        '@mdDown': {
                                            columnGap: 60,
                                        },
                                    }}
                                />
                            )}
                            {link && (
                                <LinkButton {...link} textVariant="base1" />
                            )}
                        </StyledFeaturedContent>
                    )
                )}
            </FlexWrapper>
        </Container>
    )
}

export default FeaturedContent
