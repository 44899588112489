import Button from 'src/components/atoms/Button/Button'
import { styled } from 'src/styles/stitches.config'

export const StyledBookingForm = styled('form', {
    height: '100%',
    '@mdDown': {
        marginBottom: '$md',
    },
})

export const DateWrapper = styled('div', {
    width: 'calc(4 * $colWidth)',
    input: {
        color: '$white',
        padding: 0,
    },
})

export const SubmitWrapper = styled('div', {
    height: '100%',
    width: '80%',
    '@mdDown': {
        margin: 'auto',
    },
    '@md': {
        width: 'calc(5 * $colWidth)',
    },
    [`${Button}`]: {
        width: '100%',
        height: '100%',
    },
})
