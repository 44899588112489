import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import ImageReveal from 'src/components/atoms/ImageReveal/ImageReveal'
import LinkButton from 'src/components/atoms/LinkButton/LinkButton'
import RichText from 'src/components/atoms/RichText/RichText'
import Text from 'src/components/atoms/Text/Text'
import Revealer from 'src/components/helpers/Revealer/Revealer'
import Container from 'src/components/_layout/Container/Container'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import { CmsImage, CmsLink } from 'src/types'
import Contacts, { ContactsProps } from '../Contacts/Contacts'
import {
    StyledContentWrapper,
    StyledDescription,
    StyledHorizontalImageWrapper,
    StyledImageGrid,
    StyledImageLaunch,
    StyledImageWrapper,
    StyledVerticalImageWrapper,
} from './ImageLaunch.style'

type ImageLaunchProps = {
    title: string
    subtitle?: string
    description: string
    images: Array<CmsImage>
    link?: CmsLink
    inverted?: boolean
    doubleBg?: boolean
    contacts?: boolean
    contactsData?: ContactsProps
}

const ImageLaunch: React.FC<ImageLaunchProps> = ({
    title,
    subtitle,
    description,
    images,
    link,
    inverted,
    doubleBg,
    contacts,
    contactsData,
}) => {
    const [firstImage, secondImage] = useMemo(() => images, [images])
    return (
        <StyledImageLaunch padded={doubleBg}>
            <Container>
                <FlexWrapper
                    justify="between"
                    align={images.length > 1 ? 'center' : 'start'}
                    direction={{
                        '@initial': 'column',
                        '@md': inverted ? 'rowReverse' : 'row',
                    }}
                >
                    <StyledImageWrapper padded={doubleBg}>
                        <StyledImageGrid grid={!!secondImage}>
                            <StyledVerticalImageWrapper grid={!!secondImage}>
                                <ImageReveal src={firstImage.url} />
                            </StyledVerticalImageWrapper>
                            {!!secondImage && (
                                <StyledHorizontalImageWrapper>
                                    <ImageReveal src={secondImage.url} />
                                </StyledHorizontalImageWrapper>
                            )}
                        </StyledImageGrid>
                    </StyledImageWrapper>
                    <StyledContentWrapper
                        grid={!!secondImage}
                        inverted={inverted}
                    >
                        <Padder top="md">
                            <Revealer>
                                <Padder top="xs">
                                    <Text
                                        as="h4"
                                        textVariant="heading3"
                                        dangerouslySetInnerHTML={{
                                            __html: title,
                                        }}
                                    />
                                    {subtitle && (
                                        <Text
                                            textVariant="base3"
                                            dangerouslySetInnerHTML={{
                                                __html: subtitle,
                                            }}
                                        />
                                    )}
                                </Padder>
                            </Revealer>
                            <Revealer>
                                <Padder top="lg">
                                    <StyledDescription>
                                        {!!description && (
                                            <RichText
                                                textVariant="base"
                                                htmlString={description}
                                            />
                                        )}
                                        {!!contactsData && contacts && (
                                            <Contacts {...contactsData} />
                                        )}
                                    </StyledDescription>

                                    {link && (
                                        <LinkButton
                                            {...link}
                                            textVariant="base2"
                                        />
                                    )}
                                </Padder>
                            </Revealer>
                        </Padder>
                    </StyledContentWrapper>
                </FlexWrapper>
            </Container>
        </StyledImageLaunch>
    )
}

export default ImageLaunch
