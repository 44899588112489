import Text from 'src/components/atoms/Text/Text'
import Revealer from 'src/components/helpers/Revealer/Revealer'
import Container from 'src/components/_layout/Container/Container'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import { StyledServices } from './Services.style'

type ServicesProps = {
    title: string
    description: string
    columns: Array<{ content: string }>
}

const Services: React.FC<ServicesProps> = ({ title, description, columns }) => {
    return (
        <Container>
            <Revealer>
                <Padder top="md">
                    <StyledServices>
                        <Padder top="md">
                            <Text textVariant="heading3" align="center">
                                {title}
                            </Text>
                            <Text
                                textVariant="base"
                                align="center"
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}
                            />
                        </Padder>
                    </StyledServices>
                    <FlexWrapper
                        justify="between"
                        direction={{ '@initial': 'column', '@md': 'row' }}
                    >
                        {columns.map(({ content }, index) => (
                            <Text
                                textVariant="base2"
                                textTransform="uppercase"
                                align="center"
                                css={{ lineHeight: 2 }}
                                key={index}
                                dangerouslySetInnerHTML={{ __html: content }}
                            />
                        ))}
                    </FlexWrapper>
                </Padder>
            </Revealer>
        </Container>
    )
}

export default Services
