import { styled } from 'src/styles/stitches.config'

export const StyledWhatsapp = styled('div', {
    position: 'fixed',
    bottom: '2rem',
    right: '2rem',
    zIndex: 2,
    fontSize: '3rem',
    color: '#25d366',
    '&.light': {
        color: '$white',
    },
})
