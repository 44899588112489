import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type LayoutType = {
    hasTitle: boolean
}

const initialState: LayoutType = {
    hasTitle: false,
}

const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setHasTitle: (state, action: PayloadAction<boolean>) => ({
            ...state,
            hasTitle: action.payload,
        }),
    },
})

export const { setHasTitle } = layoutSlice.actions
export const selectHasTitle = (state: any) => state.layout.hasTitle
export default layoutSlice.reducer
