import React, { useRef } from 'react'

import * as Components from 'src/components/_composer'

export interface ComposerProps {
    components: Array<any>
}

const ComposerComponent: React.FC<{ component: string }> = ({
    component,
    ...rest
}) => {
    const TagName =
        (Components as unknown as Record<string, React.FC | React.FC>)[
            component
        ] || null
    return !!TagName ? (
        <TagName {...(rest as any)} />
    ) : (
        <div>{`Component ${component} not found`}</div>
    )
}

const Composer: React.FC<ComposerProps> = ({ components }) => {
    const skipRef = useRef(-1)
    //@ts-ignore
    const getComponent = ({ component, ...rest }, index) => {
        let children: any = undefined
        if (component === 'BackgroundDivider') {
            skipRef.current = index + 1
            children = (
                <ComposerComponent
                    key={`composer-${index + 1}`}
                    {...components[index + 1]}
                />
            )
        }
        return (
            <ComposerComponent
                key={`composer-${index}`}
                component={component}
                {...rest}
            >
                {children}
            </ComposerComponent>
        )
    }

    return (
        <>
            {components.map((component, index) =>
                component && skipRef.current !== index
                    ? getComponent(component, index)
                    : null
            )}
        </>
    )
}
export default Composer
