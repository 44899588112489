import { styled } from 'src/styles/stitches.config'

export const StyledBackgroundDividerContent = styled('div', {
    paddingTop: '$sm',
    '@md': {
        paddingTop: '$xl',
    },
    variants: {
        removePadding: {
            true: {
                backgroundColor: '#fff',
            },
        },
    },
})

export const StyledBackgroundDivider = styled('div', {
    variants: {
        overlap: {
            true: {
                backgroundImage:
                    'linear-gradient(to bottom, $whiteSmoke 50%,$white 50%, $white 100%)',
                [`${StyledBackgroundDividerContent}`]: {
                    paddingTop: 0,
                },
            },
        },
        removeMargin: {
            true: {
                marginTop: '0!important',
            },
        },
    },
})
