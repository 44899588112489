import { styled } from 'src/styles/stitches.config'

export const StyledLanguageSwitcher = styled('div', {
    position: 'relative',
    svg: {
        width: '2rem',
    },
})

export const StyledSecondLang = styled('div', {
    position: 'absolute',
    top: 'calc(100% + 20px)',
    left: 0,
    width: '100%',
})
