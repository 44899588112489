import { styled } from 'src/styles/stitches.config'

export const StyledMainMenutem = styled('div', {
    position: 'relative',
    '&::after': {
        position: 'absolute',
        bottom: -2,
        left: 0,
        backgroundColor: '$green',
        width: 0,
        height: 1,
        transition: 'width .4s ease-out',
        content: '',
    },

    '@hover': {
        '&:hover::after': {
            width: '100%',
        },
    },
})
