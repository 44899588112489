import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Image from 'src/components/atoms/Image/Image'
import RichText from 'src/components/atoms/RichText/RichText'
import Text from 'src/components/atoms/Text/Text'
import Container from 'src/components/_layout/Container/Container'
import Padder from 'src/components/_layout/Padder/Padder'
import { setHasTitle } from 'src/state/layout'
import { CmsImage } from 'src/types'
import {
    StyledImageContent,
    StyledPageTitle,
    StyledPageTitleContent,
} from './PageTitle.style'

type PageTitleProps = {
    title: string
    subtitle?: string
    image?: CmsImage
}
const PageTitle: React.FC<PageTitleProps> = ({ title, subtitle, image }) => {
    const dispatch = useDispatch()

    useEffect(() => {
        if (!!image) {
            dispatch(setHasTitle(true))
        }

        return () => {
            dispatch(setHasTitle(false))
        }
    }, [dispatch, image])

    return (
        <StyledPageTitle>
            {!!image && (
                <StyledImageContent>
                    <Image src={image?.url} />
                </StyledImageContent>
            )}
            <StyledPageTitleContent plain={!image}>
                <Padder as={Container} top="md">
                    <Text
                        align="center"
                        textVariant={!!image ? 'heading1' : 'heading4'}
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                    {subtitle && (
                        <RichText
                            htmlString={subtitle}
                            textVariant={!!image ? 'base3' : 'plain2'}
                            align="center"
                        />
                    )}
                </Padder>
            </StyledPageTitleContent>
        </StyledPageTitle>
    )
}

export default PageTitle
