import { styled } from 'src/styles/stitches.config'

export const StyledFooter = styled('div', {
    marginTop: '$lg',
    padding: '$md 0',
    backgroundColor: '$white',
    '@md': {
        marginTop: '$lg',
    },
})

export const StyledPreFooter = styled('div', {})

export const FooterLogo = styled('div', {
    width: '60px',
    '@md': {
        width: 'calc($colWidth / 2)',
    },
})
