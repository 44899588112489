import { Link } from 'react-router-dom'
import ImageReveal from 'src/components/atoms/ImageReveal/ImageReveal'
import Text from 'src/components/atoms/Text/Text'
import Container from 'src/components/_layout/Container/Container'
import { CmsImage, CmsLink } from 'src/types'
import { StyledBannerContent, StyledImageWrapper } from './Banner.style'

const Banner: React.FC<{ image: CmsImage; link: CmsLink }> = ({
    image: { url, alt },
    link,
}) => {
    return (
        <Container css={{ position: 'relative' }}>
            <StyledImageWrapper>
                <ImageReveal src={url} alt={alt} />
            </StyledImageWrapper>
            {link && (
                <StyledBannerContent>
                    <Link to={link.url}>
                        <Text
                            textVariant={{
                                '@initial': 'base3',
                                '@md': 'heading4',
                            }}
                        >
                            {link.title}
                        </Text>
                    </Link>
                </StyledBannerContent>
            )}
        </Container>
    )
}

export default Banner
