import { styled } from 'src/styles/stitches.config'

export const StyledLinkButton = styled('div', {
    position: 'relative',

    '&::after': {
        content: '',
        position: 'absolute',
        top: 'calc(100% + 2px)',
        left: 0,
        width: '100%',
        height: 2,
        backgroundColor: '$black',
        transition: 'width .25s ease-out',
    },

    '@hover': {
        '&::after': {
            width: '60%',
        },
        '&:hover::after': {
            width: '100%',
        },
    },
})
