import { styled } from 'src/styles/stitches.config'

export const StyledPopup = styled('div', {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    height: '70vh',
    width: 'auto',
    margin: 'auto',
    backgroundColor: 'transparent',
    padding: 0,
    color: '#fff',
    zIndex: 1200,
    borderRadius: 15,
    overflow: 'hidden',
    boxShadow: '0 22px 54px 0 rgb(0 0 0 / 8%)',

    img: { position: 'relative', height: '70vh', width: 'auto', zIndex: 2 },

    '@mdDown': {
        padding: '30px',
        width: '80%',
        height: '60%',

        img: {
            width: '100%',
            height: 'auto',
        },
    },
})

export const StyledCloser = styled('button', {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 5,
    top: 5,
    width: '2.5rem',
    height: '2.5rem',
    cursor: 'pointer',
    backgroundColor: '#000',
    borderRadius: '50%',
    color: '#fff',
    fontSize: '25px',
    zIndex: 10000,
})
