import Button from 'src/components/atoms/Button/Button'
import { Social } from 'src/components/molecules/SiteLinks/SiteLinks.style'
import { styled } from 'src/styles/stitches.config'

export const StyledHeader = styled('div', {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 5,
    paddingTop: '$sm',
    paddingBottom: '$sm',
    color: '$green',

    [`${Button}`]: {
        borderColor: '$green',
        color: '$green',
        fontWeight: '$medium',
    },

    '.logo-svg': {
        fill: '$green',
    },

    '&.light': {
        color: '$white',
        [`${Button}`]: {
            borderColor: '$white',
            color: '$white',
            fontWeight: '$medium',
        },

        [`${Social}`]: {
            backgroundColor: '$white',
            color: '$green',
        },

        '.logo-svg': {
            fill: '$white',
        },
    },
})
