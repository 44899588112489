import { useCallback, useState } from 'react'
import { VscClose } from 'react-icons/vsc'
import { useSelector } from 'react-redux'
import Image from 'src/components/atoms/Image/Image'
import { getSettings } from 'src/selectors/settings'
import { StyledCloser, StyledPopup } from './Popup.style'

const Popup: React.FC = () => {
    const {
        popup: { image, isActive },
    } = useSelector(getSettings)

    const [isPopupActive, setIsPopupActive] = useState(isActive)

    const onClose = useCallback(() => {
        setIsPopupActive(false)
    }, [])

    return isPopupActive && !!image ? (
        <StyledPopup>
            <StyledCloser onClick={onClose}>
                <VscClose />
            </StyledCloser>
            <Image src={image.url} />
        </StyledPopup>
    ) : null
}

export default Popup
