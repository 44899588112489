import type * as Stitches from '@stitches/react'
import { styled, theme } from 'src/styles/stitches.config'

const Text = styled('div', {
    fontFamily: '$primary',
    fontSize: '1rem',
    fontWeight: '$normal',
    variants: {
        color: Object.fromEntries(
            Object.keys(theme.colors).map((colorKey) => [
                colorKey,
                { color: `$${colorKey}` },
            ])
        ),
        align: {
            center: { textAlign: 'center' },
            right: { textAlign: 'right' },
            left: { textAlign: 'left' },
            justify: { textAlign: 'justify' },
        },
        textTransform: {
            uppercase: {
                textTransform: 'uppercase',
            },
            lowercase: {
                textTransform: 'lowercase',
            },
        },
        textVariant: {
            heading1: {
                fontFamily: '$primary',
                fontWeight: '$normal',
                lineHeight: 1.2,
                fontSize: '4.06rem',
                '@mdDown': {
                    fontSize: '2rem',
                },
            },
            heading2: {
                fontFamily: '$primary',
                fontWeight: '$normal',
                lineHeight: 1,
                fontSize: '3rem',
                '@mdDown': {
                    fontSize: '2rem',
                },
            },
            heading2Bis: {
                fontFamily: '$primary',
                fontWeight: '$bold',
                lineHeight: 1,
                fontSize: '2.5rem',
                '@mdDown': {
                    fontSize: '1.7rem',
                },
            },
            heading3: {
                fontFamily: '$primary',
                fontWeight: '$bold',
                lineHeight: 0.7,
                fontSize: '4.375rem',
                '@mdDown': {
                    fontSize: '2.35rem',
                },
            },
            heading3Bis: {
                fontFamily: '$primary',
                fontWeight: '$bold',
                lineHeight: 0.7,
                fontSize: '3rem',
                '@mdDown': {
                    fontSize: '2.35rem',
                },
            },

            heading4: {
                fontFamily: '$primary',
                fontWeight: '$normal',
                lineHeight: 1,
                fontSize: '2rem',
            },
            heading5: {
                fontFamily: '$secondary',
                lineHeight: 1,
                fontSize: '1.83rem',
                fontWeight: '$medium',
            },
            base: {
                fontFamily: '$secondary',
                fontWeight: '$normal',
                lineHeight: '1.2',
                fontSize: '1.25rem',
            },
            base1: {
                fontFamily: '$primary',
                fontWeight: '$normal',
                lineHeight: '1.4',
                fontSize: '0.85rem',
                '@mdDown': {
                    fontSize: '0.7rem',
                },
            },
            base2: {
                fontFamily: '$primary',
                fontWeight: '$normal',
                lineHeight: '1.4',
                fontSize: '0.95rem',
                '@mdDown': {
                    fontSize: '0.8rem',
                },
            },
            base3: {
                fontFamily: '$primary',
                fontWeight: '$normal',
                lineHeight: '1.2',
                fontSize: '1.31rem',
                '@mdDown': {
                    fontSize: '1rem',
                },
            },
            plain: {
                fontFamily: '$plain',
                fontWeight: '$plain',
                lineHeight: '1.2',
                fontSize: '1rem',
            },
            plain2: {
                fontFamily: '$plain',
                fontWeight: '$plain',
                lineHeight: '1.2',
                fontSize: '1.3rem',
            },
            small: {
                fontFamily: '$primary',
                fontWeight: '$normal',
                fontSize: '0.85rem',
                lineHeight: '1.3',
            },
            small2: {
                fontFamily: '$primary',
                fontWeight: '$normal',
                fontSize: '0.75rem',
                lineHeight: '1.3',
            },
        },
    },
})

export type TextVariants = Stitches.VariantProps<typeof Text>

export default Text
