import Text from 'src/components/atoms/Text/Text'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import { Social } from './SiteLinks.style'
import { FaFacebookF, FaInstagram } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import Padder from 'src/components/_layout/Padder/Padder'
import useLabel from 'src/hooks/useLabel'
import { getSettings } from 'src/selectors/settings'

const SiteLinks: React.FC = (props) => {
    const { social } = useSelector(getSettings)
    const label = useLabel()

    return (
        <>
            <Text textVariant="base">
                <a href="/it">ITA</a> | <a href="/en">ENG</a>
            </Text>
            {!!social && (
                <FlexWrapper
                    as={Padder}
                    left={{ '@initial': 'zero', '@md': 'xxs' }}
                    justify={{ '@initial': 'center', '@md': 'start' }}
                    css={{ '@mdDown': { marginTop: '$xs' } }}
                >
                    <Text
                        textVariant="base"
                        css={{ '@mdDown': { display: 'none' } }}
                    >
                        {label('Follow us')}
                    </Text>
                    <FlexWrapper
                        as={Padder}
                        left="xxs"
                        align="center"
                        justify={{ '@initial': 'center', '@md': 'start' }}
                    >
                        <Social>
                            <a
                                href={social.instagram}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FaInstagram />
                            </a>
                        </Social>
                        <Social>
                            <a
                                href={social.facebook}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FaFacebookF />
                            </a>
                        </Social>
                    </FlexWrapper>
                </FlexWrapper>
            )}
        </>
    )
}

export default SiteLinks
