import { useResponsive } from 'ahooks'
import { useEffect, useState } from 'react'
import { MutableRefObject, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Button from 'src/components/atoms/Button/Button'
import Text from 'src/components/atoms/Text/Text'
import BookingForm from 'src/components/molecules/BookingForm/BookingForm'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import useLabel from 'src/hooks/useLabel'
import { getSettings } from 'src/selectors/settings'
import { StyledHorizontalBooking } from './HorizontalBooking.style'
const HorizontalBooking: React.FC = (props) => {
    const responsiveInfo = useResponsive()
    const [isMounted, setIsMounted] = useState<boolean>(false)
    const { booking } = useSelector(getSettings)
    const label = useLabel()

    const [ref, inView, entry] = useInView({
        triggerOnce: false,
        threshold: 0.5,
    })

    useEffect(() => {
        if (!isMounted) {
            setIsMounted(true)
            return
        }
        if (!inView && entry?.intersectionRect.y === 0) {
            document.querySelector('#header')?.classList.remove('light')
        } else {
            document.querySelector('#header')?.classList.add('light')
        }
    }, [inView, isMounted])

    return (
        <StyledHorizontalBooking
            css={!responsiveInfo.md ? { paddingLeft: 0 } : undefined}
            ref={ref}
        >
            {responsiveInfo.md ? (
                <BookingForm />
            ) : (
                <FlexWrapper
                    justify="center"
                    css={{
                        a: {
                            width: '100%',
                        },
                        '@md': {
                            padding: '$xs',
                            a: {
                                width: '80%',
                            },
                        },
                    }}
                >
                    <Link to={booking.link.url}>
                        <Button variant="whiteFill" css={{ width: '100%' }}>
                            <Text
                                textVariant="base2"
                                css={{ fontWeight: '$medium' }}
                            >
                                {label('Book now').toUpperCase()}
                            </Text>
                        </Button>
                    </Link>
                </FlexWrapper>
            )}
        </StyledHorizontalBooking>
    )
}

export default HorizontalBooking
