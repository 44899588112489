import Text from 'src/components/atoms/Text/Text'
import Container from 'src/components/_layout/Container/Container'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'

type TipsProps = {
    items: Array<{
        title: string
        description: string
    }>
}
const Tips: React.FC<TipsProps> = ({ items }) => {
    return (
        <Container>
            <FlexWrapper
                as={Padder}
                justify="between"
                direction={{ '@initial': 'column', '@md': 'row' }}
                css={{ '@mdDown': { marginTop: '$md' } }}
                top={{ '@mdDown': 'sm' }}
            >
                {items.map(({ title, description }, index) => (
                    <Padder key={index} top="xxs" css={{ textAlign: 'center' }}>
                        <Text textVariant="heading3">{title}</Text>
                        <Text
                            textVariant="base2"
                            dangerouslySetInnerHTML={{ __html: description }}
                        />
                    </Padder>
                ))}
            </FlexWrapper>
        </Container>
    )
}

export default Tips
