import { styled } from 'src/styles/stitches.config'

export const StyledPageTitle = styled('div', {
    position: 'relative',
})

export const StyledImageContent = styled('div', {
    position: 'relative',
    height: '92.5vh',
    backgroundColor: '$green',
    overflow: 'hidden',
    '> img': {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
    },
    '@mdDown': {
        height: '80vh',
    },
})

export const StyledPageTitleContent = styled('div', {
    position: 'absolute',
    top: '50%',
    left: 0,
    right: 0,
    margin: 'auto',
    transform: 'translateY(-50%)',
    color: '$white',

    variants: {
        plain: {
            true: {
                position: 'relative',
                top: 0,
                left: 0,
                right: 0,
                margin: 'auto',
                transform: 'none',
                color: '$green',
                paddingTop: '$xxl',
            },
        },
    },
})
