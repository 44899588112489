import { styled } from 'src/styles/stitches.config'

export const StyledImageWrapper = styled('div', {
    position: 'relative',

    '@mdDown': {
        ratio: {
            w: 3,
            h: 4,
        },
        marginTop: '$lg',
        img: {
            objectFit: 'cover',
        },
    },
})

export const StyledBannerContent = styled('div', {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '$white',
    padding: '$sm $md',
    border: 'solid 2px $white',

    '@mdDown': {
        width: '70%',
        padding: '$xs',
        textAlign: 'center',
    },
})
