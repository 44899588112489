import { styled } from 'src/styles/stitches.config'

export const StyledLogo = styled('div', {
    position: 'relative',
    maxWidth: '10rem',
    '@md': {
        maxWidth: '13rem',
    },

    '.s0': { fill: '#435a47' },

    '.light & .s0': { fill: '#fff' },
})
