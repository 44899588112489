import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import Button from 'src/components/atoms/Button/Button'
import Input from 'src/components/atoms/Input/Input'
import Text from 'src/components/atoms/Text/Text'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import useLabel from 'src/hooks/useLabel'
import { getSettings } from 'src/selectors/settings'
import './BookingForm.css'
import {
    DateWrapper,
    StyledBookingForm,
    SubmitWrapper,
} from './BookingForm.style'

const BookingForm: React.FC<{ column?: boolean }> = ({ column }) => {
    const { booking } = useSelector(getSettings)
    const label = useLabel()
    const { push } = useHistory()
    const [checkinDate, setCheckinDate] = useState<Date>(new Date())
    const [checkoutDate, setCheckoutDate] = useState<Date>(new Date())
    const onFormSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>(
        (e) => {
            e.preventDefault()
            const params = {
                'check-in': checkinDate?.toLocaleDateString('en-US'),
                'check-out': checkoutDate?.toLocaleDateString('en-US'),
            }

            const stringParams = Object.keys(params)
                //@ts-ignore
                .map((key) => key + '=' + params[key])
                .join('&')

            push(`${booking.link.url}?${stringParams}`)
        },
        [booking.link.url, checkinDate, checkoutDate, push]
    )

    return (
        <StyledBookingForm onSubmit={onFormSubmit}>
            <FlexWrapper
                as={Padder}
                css={!column ? { height: '100%' } : undefined}
                {...(column ? { top: 'sm' } : { left: 'sm' })}
                justify={column ? 'start' : 'between'}
                align={column ? 'start' : 'center'}
                direction={column ? 'column' : 'row'}
            >
                <DateWrapper css={{ width: column ? '100%' : '50%' }}>
                    <FlexWrapper
                        align="center"
                        as={Padder}
                        left="xs"
                        direction={column ? 'column' : 'row'}
                    >
                        <Text textVariant="plain" css={{ fontWeight: '$bold' }}>
                            {label('Check-in')}
                        </Text>
                        <div className="picker-wrapper">
                            <Input
                                type="date"
                                name="check-in"
                                //@ts-ignore
                                value={checkinDate}
                                //@ts-ignore
                                onChange={setCheckinDate}
                                maxDate={
                                    booking.maxDate
                                        ? new Date(booking.maxDate)
                                        : undefined
                                }
                            />
                        </div>
                    </FlexWrapper>
                </DateWrapper>
                <DateWrapper css={{ width: column ? '100%' : '50%' }}>
                    <FlexWrapper align="center" as={Padder} left="xs">
                        <Text textVariant="plain" css={{ fontWeight: '$bold' }}>
                            {label('Check-out')}
                        </Text>
                        <div className="picker-wrapper">
                            <Input
                                type="date"
                                name="check-out"
                                //@ts-ignore
                                value={checkoutDate}
                                //@ts-ignore
                                onChange={setCheckoutDate}
                                maxDate={
                                    booking.maxDate
                                        ? new Date(booking.maxDate)
                                        : undefined
                                }
                            />
                        </div>
                    </FlexWrapper>
                </DateWrapper>

                <SubmitWrapper>
                    <Button variant={column ? 'blueFill' : 'whiteFill'}>
                        <Text textVariant="base3" css={{ fontWeight: '$bold' }}>
                            {label('Book now').toUpperCase()}
                        </Text>
                    </Button>
                </SubmitWrapper>
            </FlexWrapper>
        </StyledBookingForm>
    )
}

export default BookingForm
