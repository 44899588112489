import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { motion } from 'framer-motion'
import { getSettings } from 'src/selectors/settings'

import { StyledLogo } from './Logo.style'

const Logo: React.FC = (props) => {
    const { logo, language } = useSelector(getSettings)

    return (
        <StyledLogo
            as={motion.div}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.15, ease: 'easeOut' }}
        >
            <Link to={`/${language}`}>
                <svg
                    viewBox="0 0 204 79"
                    style={{ width: '100%' }}
                    className="logo-svg"
                >
                    <g>
                        <g>
                            <path
                                className="s0"
                                d="m3.9 48.5q0.6 0 1.1-0.3 0.5-0.4 0.5-1.5v-21.7q-0.5 0-1.1 0.1-0.6 0.1-1.2 0.2-0.6 0.2-1.1 0.5-0.4 0.3-0.6 0.8h-0.8v-8.2h0.8q0 0.6 0.5 0.8 0.6 0.3 1.2 0.3 1.1 0 1.8-0.7 0.7-0.7 0.7-1.7 0-0.9-0.4-1.2-0.4-0.2-1.1-0.3v-0.8h10.5v0.8q-0.8 0.1-1.3 0.4-0.5 0.3-0.5 1v29.7q0 1.1 0.5 1.5 0.5 0.3 1.2 0.3v0.8h-10.7z"
                            />
                            <path
                                fillRule="evenodd"
                                className="s0"
                                d="m50.2 31.1q3.4 0.8 5.5 2.6 2.1 1.9 2.1 5.6 0 3.3-1.8 5.3-1.8 2.1-4.4 3.2-2.6 1.1-5.5 1.4-3 0.3-5.4 0.3-2.5 0-5.3-0.4-2.7-0.4-5.1-1.5-2.3-1.1-3.8-3-1.6-1.9-1.6-4.8 0-2 0.6-3.4 0.5-1.4 1.6-2.4 1-1 2.5-1.7 1.4-0.6 3.2-1.2v-0.1q-2.6-0.5-4.5-2.2-1.9-1.7-1.9-4.7 0-3 1.4-4.8 1.4-1.9 3.6-2.9 2.2-1 4.8-1.3 2.6-0.3 5-0.3 2.2 0 4.9 0.3 2.7 0.3 5 1.3 2.3 1 3.8 2.8 1.6 1.8 1.6 4.7 0 3.2-1.8 4.8-1.7 1.6-4.5 2.4zm-8.8 2.4q-9.3 0-9.3 5.4 0 5.4 9.3 5.4 9.2 0 9.2-5.4 0-5.4-9.2-5.4zm0-13.7q-4.3 0-6.1 1-1.8 0.9-1.8 3.2 0 2.3 1.8 3.2 1.8 1 6.1 1 4.3 0 6.1-1 1.8-0.9 1.8-3.2 0-2.3-1.8-3.2-1.8-1-6.1-1z"
                            />
                            <path
                                fillRule="evenodd"
                                className="s0"
                                d="m133.2 39.3l0.8 0.3q0 0.4 0.1 0.8 0.1 0.5 0.3 0.8 0.7 0.9 1.8 1.5 1.1 0.6 2.4 1 1.2 0.4 2.5 0.5 1.3 0.2 2.4 0.2 10.1 0 10.7-10.2-2.7 1.4-5.4 2.3-2.7 1-5.7 1-2.4 0-4.8-0.6-2.5-0.6-4.5-1.8-2-1.2-3.2-3.2-1.3-1.9-1.3-4.7 0-3.3 1.5-5.6 1.4-2.3 3.8-3.9 2.3-1.5 5.2-2.2 2.9-0.7 5.7-0.7 3.6 0 6.6 1.2 2.9 1.2 5.1 3.4 2.1 2.1 3.3 5.1 1.1 3 1.1 6.6 0 4.2-1.3 7.6-1.3 3.4-3.8 5.9-2.5 2.4-5.9 3.7-3.4 1.2-7.6 1.2-2.9 0-5-0.4-2-0.4-3.4-1-1.3-0.7-2.2-1.1-0.8-0.5-1.3-0.5-0.8 0-1.3 0.4l-0.5-0.5zm11.8-18.9q-1.6 0-3.1 0.3-1.5 0.3-2.7 1-1.1 0.7-1.8 1.8-0.7 1.1-0.7 2.8 0 1.7 0.7 2.8 0.7 1.2 1.8 1.9 1.2 0.6 2.7 0.9 1.5 0.3 3.1 0.3 1.5 0 3-0.3 1.5-0.3 2.7-0.9 1.1-0.7 1.8-1.9 0.7-1.1 0.7-2.8 0-1.7-0.7-2.8-0.7-1.1-1.8-1.8-1.2-0.7-2.7-1-1.5-0.3-3-0.3z"
                            />
                            <path
                                fillRule="evenodd"
                                className="s0"
                                d="m185.2 14.8q3.7 0 7 1.3 3.3 1.3 5.7 3.6 2.4 2.4 3.8 5.5 1.4 3.2 1.4 7 0 3.9-1.4 7.1-1.4 3.1-3.8 5.4-2.4 2.4-5.7 3.6-3.3 1.2-7 1.2-3.8 0-7.1-1.2-3.3-1.2-5.7-3.6-2.4-2.3-3.8-5.4-1.4-3.2-1.4-7.1 0-3.8 1.4-7 1.4-3.1 3.8-5.5 2.4-2.3 5.7-3.6 3.3-1.3 7.1-1.3zm0 5.6q-2.3 0-4.3 0.9-1.9 0.9-3.3 2.5-1.4 1.6-2.2 3.7-0.8 2.2-0.8 4.7 0 2.6 0.8 4.8 0.8 2.1 2.2 3.7 1.4 1.6 3.3 2.5 2 0.9 4.3 0.9 2.2 0 4.2-0.9 1.9-0.9 3.3-2.5 1.4-1.6 2.2-3.7 0.8-2.2 0.8-4.8 0-2.5-0.8-4.7-0.8-2.1-2.2-3.7-1.4-1.6-3.3-2.5-2-0.9-4.2-0.9z"
                            />
                        </g>
                        <g>
                            <path
                                className="s0"
                                d="m114.7 25c1.7 2.7 1.7 6.4 0 8.8-1.7 2.5-5.4 3.6-8.9 2.8 0-0.2 0.1-0.3 0.2-0.4 1-1.3 1.1-2.8 0.2-4.1-0.9-1.5-2.4-1.6-4-1.2-2.4 0.5-3.6 2.2-4.6 4.2-0.4 0.8-0.7 1.5-1 2.3 1.8 0.5 3.1 2.2 3.1 4.1 0 2.4-1.9 4.3-4.3 4.3-2.4 0-4.3-1.9-4.3-4.3 0-1.9 1.3-3.6 3.1-4.1-0.3-0.8-0.6-1.5-1-2.3-1-2-2.3-3.7-4.6-4.2-1.6-0.4-3.1-0.3-4 1.2-0.9 1.3-0.8 2.8 0.2 4.1 0.1 0.1 0.2 0.2 0.2 0.4-3.5 0.8-7.2-0.3-8.9-2.8-1.7-2.4-1.7-6.1 0-8.8 1.9-2.9 5.2-4.4 8.7-3.7 4.3 0.7 7.3 3.1 8.5 7.3 0.8 2.8 1.1 5.8 1.6 8.7q0 0 0 0c0-1.6 0-3 0-3.4 0.2-10.5-1.9-10.1-6.1-14.2-4.3-4.2-4.2-8.9-0.1-13.1 2.2-2.2 4.5-4.4 6.7-6.6q0 0 0 0 0 0 0 0c2.2 2.2 4.5 4.4 6.7 6.6 4.1 4.2 4.1 8.9-0.1 13.1-4.2 4.1-6.3 3.7-6.1 14.2 0 0.4 0 1.8 0 3.4q0 0 0 0c0.5-2.9 0.8-5.9 1.6-8.7 1.1-4.2 4.2-6.6 8.5-7.3 3.4-0.7 6.8 0.8 8.7 3.7z"
                            />
                            <path
                                className="s0"
                                d="m98.2 49.4c0 1.6-1.3 2.8-2.8 2.8-1.5 0-2.8-1.2-2.8-2.8 0-1.5 1.3-2.7 2.8-2.7 1.5 0 2.8 1.2 2.8 2.7z"
                            />
                            <path
                                className="s0"
                                d="m97.3 55c0 1-0.9 1.9-1.9 1.9-1.1 0-1.9-0.9-1.9-1.9 0-1.1 0.8-1.9 1.9-1.9 1 0 1.9 0.8 1.9 1.9z"
                            />
                            <path
                                className="s0"
                                d="m96.5 59c0 0.6-0.5 1.1-1.1 1.1-0.6 0-1.1-0.5-1.1-1.1 0-0.6 0.5-1.1 1.1-1.1 0.6 0 1.1 0.5 1.1 1.1z"
                            />
                            <path
                                className="s0"
                                d="m107.3 13.1c0-1.1 0.9-2 2-2 1.1 0 2 0.9 2 2 0 1.1-0.9 2-2 2-1.1 0-2-0.9-2-2z"
                            />
                            <path
                                className="s0"
                                d="m111.9 13.1c0-0.8 0.6-1.4 1.4-1.4 0.7 0 1.3 0.6 1.3 1.4 0 0.7-0.6 1.4-1.3 1.4-0.8 0-1.4-0.7-1.4-1.4z"
                            />
                            <path
                                className="s0"
                                d="m116.9 13.1c0 0.4-0.3 0.8-0.8 0.8-0.4 0-0.8-0.4-0.8-0.8 0-0.5 0.4-0.8 0.8-0.8 0.5 0 0.8 0.3 0.8 0.8z"
                            />
                            <path
                                className="s0"
                                d="m79.1 13.1c0-1.1 0.9-2 2-2 1.1 0 2 0.9 2 2 0 1.1-0.9 2-2 2-1.1 0-2-0.9-2-2z"
                            />
                            <path
                                className="s0"
                                d="m75.8 13.1c0-0.8 0.6-1.4 1.3-1.4 0.8 0 1.4 0.6 1.4 1.4 0 0.7-0.6 1.4-1.4 1.4-0.7 0-1.3-0.7-1.3-1.4z"
                            />
                            <path
                                className="s0"
                                d="m74.3 13.9c-0.5 0-0.8-0.4-0.8-0.8 0-0.5 0.3-0.8 0.8-0.8 0.4 0 0.8 0.3 0.8 0.8 0 0.4-0.4 0.8-0.8 0.8z"
                            />
                        </g>
                        <g>
                            <path
                                fillRule="evenodd"
                                className="s0"
                                d="m54.9 72.5v0.2h-0.1q0 0 0 0 0 0-0.1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1-0.1 0.1 0 0 0 0h0.1l2.6 5.5q0 0 0 0 0 0 0 0 0.1 0.1 0.1 0.1 0 0 0.1 0.1 0 0 0 0 0 0 0 0 0.1 0 0.1 0 0 0 0 0 0 0 0 0 0.1 0 0.1 0v0.1h-1.6q0-0.1 0-0.1 0.1 0 0.1 0 0 0 0 0 0.1 0 0.1-0.1v-0.1l-0.6-1.2h-3.3l-0.6 1.2v0.2h0.2v0.1h-1.4v-0.1h0.2l2.6-5.7q0 0 0 0 0 0-0.1-0.1 0 0 0 0 0 0 0 0 0 0 0 0-0.1 0-0.1 0 0 0 0 0 0 0-0.1 0v-0.2zm-2.1 3.7h2.5l-1.3-2.6z"
                            />
                            <path
                                className="s0"
                                d="m60.8 72.5v0.2l-0.2 0.1 3.7 4.2h0.1v-4.2l-0.3-0.1q0 0 0 0 0-0.1 0-0.1 0 0 0 0 0 0 0-0.1h1.4v0.2q0 0-0.1 0-0.1 0.1-0.1 0.1v5.6q0 0 0 0 0.1 0 0.1 0.1 0 0 0.1 0 0 0 0 0v0.1h-1.5v-0.1l0.3-0.1-4.3-4.8h-0.1v4.8l0.3 0.1v0.1h-1.4v-0.1q0 0 0.1 0 0 0 0 0 0.1 0 0.1 0 0-0.1 0.1-0.1v-5.6l-0.3-0.1v-0.2z"
                            />
                            <path
                                className="s0"
                                d="m67 72.3l0.1 0.2h5.6l0.1-0.2h0.1v1.3h-0.1q0 0 0-0.1 0 0-0.1 0 0 0 0-0.1 0 0 0 0 0 0 0 0 0-0.1 0-0.1h-2.3v5.1l0.3 0.1v0.1h-1.6v-0.1l0.3-0.1v-5.1h-2.3l-0.1 0.3h-0.2v-1.3z"
                            />
                            <path
                                className="s0"
                                d="m75.7 72.5v0.2l-0.2 0.1v5.6l0.2 0.1v0.1h-1.5v-0.1l0.3-0.1v-5.6q0 0 0 0 0 0 0 0-0.1 0-0.2-0.1 0 0-0.1 0v-0.2z"
                            />
                            <path
                                className="s0"
                                d="m80.2 72.5q0.1 0 0.2 0 0.1 0 0.2 0 0.1 0 0.2 0 0.2 0 0.3 0 0.2 0 0.3 0 0.6 0.1 1.1 0.2 0.5 0.2 0.9 0.4 0 0 0 0 0 0 0.1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0 0 0 0 0 0 0 0 0 0 0 0.1-0.1 0 0 0 0 0 0 0 0 0 0 0-0.1l0.2 0.1-0.8 1.3h-0.1v-0.3q-0.3-0.2-0.7-0.4-0.4-0.2-0.8-0.3-0.2 0-0.4 0-0.2 0-0.4 0-0.1 0-0.3 0-0.1 0-0.2 0-0.3 0.1-0.6 0.2-0.2 0.1-0.5 0.2-0.4 0.4-0.7 0.8-0.3 0.5-0.3 1 0 0.1 0.1 0.3 0 0.1 0 0.2 0 0.1 0 0.1 0 0.1 0.1 0.1 0.2 0.6 0.6 1 0.4 0.3 1 0.5 0.1 0.1 0.2 0.1 0.1 0 0.2 0 0.1 0 0.3 0.1 0.1 0 0.2 0 0.6 0 1.1-0.2 0.5-0.2 1-0.5 0 0 0 0 0 0 0-0.1 0.1 0 0.1 0 0.1-0.1 0.1-0.1 0 0 0 0 0-0.1 0-0.1 0 0 0 0-0.1 0-0.1 0 0-0.1 0-0.1 0 0 0 0 0 0 0.1 0 0 0 0 0 0-0.1 0-0.1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1-0.1l0.9 1.2q0 0-0.1 0 0 0 0 0 0 0 0 0.1-0.1 0-0.1 0 0-0.1 0-0.1 0 0-0.1 0 0 0 0 0 0 0 0 0 0 0 0 0 0-0.1 0-0.1-0.5 0.4-1.2 0.6-0.6 0.3-1.3 0.4-0.1 0-0.3 0-0.1 0-0.3 0-0.6 0-1.3-0.2-0.6-0.2-1.1-0.6-0.5-0.5-0.8-1.1-0.3-0.6-0.3-1.3 0-0.1 0-0.2 0-0.1 0-0.2 0.1-0.1 0.1-0.2 0-0.1 0-0.2 0 0 0 0 0-0.1 0-0.1 0.3-0.9 1.1-1.4 0.7-0.5 1.6-0.7 0.1 0 0.2 0z"
                            />
                            <path
                                fillRule="evenodd"
                                className="s0"
                                d="m89.2 72.5v0.2h-0.1q0 0 0 0 0 0 0 0-0.1 0-0.1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0 0.1 0 0 0 0l2.6 5.5q0 0 0 0 0 0 0 0 0.1 0.1 0.1 0.1 0.1 0 0.1 0.1 0 0 0 0 0 0 0.1 0 0 0 0 0 0 0 0 0 0 0 0.1 0 0 0 0 0v0.1h-1.6q0-0.1 0-0.1 0.1 0 0.1 0 0 0 0.1 0 0 0 0-0.1v-0.1l-0.6-1.2h-3.3l-0.5 1.2v0.2h0.1v0.1h-1.4v-0.1h0.2l2.6-5.7q0 0 0 0 0 0 0-0.1 0 0-0.1 0 0 0 0 0 0 0 0 0 0 0-0.1 0 0 0 0 0 0 0 0 0v-0.2zm-2.2 3.7h2.5l-1.2-2.6z"
                            />
                            <path
                                fillRule="evenodd"
                                className="s0"
                                d="m101 72.5q0.9 0.1 1.6 0.6 0.7 0.5 0.9 1.4 0 0.1 0 0.3 0.1 0.1 0.1 0.2 0 0.2 0 0.3 0 0.2 0 0.3 0 0.7-0.2 1.3-0.2 0.6-0.7 1.1-0.3 0.3-0.7 0.4-0.4 0.2-0.8 0.2h-4.2v-0.1q0.1 0 0.1 0 0 0 0.1 0 0 0 0 0 0.1 0 0.1-0.1v-5.7h-0.3v-0.2zm-2.8 5.4h2.9q0.8-0.1 1.2-0.8 0.4-0.6 0.4-1.4 0 0 0-0.1 0 0 0 0v-0.2q0-0.1 0-0.2 0-0.1 0-0.2-0.1-0.2-0.1-0.3 0-0.2-0.1-0.3-0.2-0.4-0.6-0.7-0.4-0.3-0.9-0.3h-2.8z"
                            />
                            <path
                                className="s0"
                                d="m106.6 72.5v0.2l-0.3 0.1v5.6l0.3 0.1v0.1h-1.6v-0.1l0.3-0.1v-5.6q0 0 0 0 0 0 0 0 0 0-0.1-0.1-0.1 0-0.2 0v-0.2z"
                            />
                            <path
                                className="s0"
                                d="m110.4 72.5v0.2l-0.3 0.1 2 3.7h0.1l1.9-3.7q0 0 0 0 0 0 0 0 0 0-0.1-0.1-0.1 0-0.1 0v-0.2h2.1q0 0.1 0 0.1 0 0 0 0-0.1 0-0.1 0 0 0.1 0 0.1 0 0-0.1 0 0 0 0 0-0.1 0.1-0.1 0.1 0 0-0.1 0v5.6q0 0 0.1 0 0 0 0 0 0.1 0.1 0.1 0.1 0.1 0 0.2 0v0.1h-1.7v-0.1l0.3-0.1v-4.5h-0.1l-2.4 4.6-2.4-4.5h-0.1v4.5h0.3v0.1h-1.4v-0.1l0.3-0.1v-5.6l-0.4-0.1v-0.2z"
                            />
                            <path
                                fillRule="evenodd"
                                className="s0"
                                d="m120.5 72.5q0.1 0 0.1 0 0.1 0 0.1 0 0.1 0 0.2 0 1.3 0 2.3 0.8 1.1 0.8 1.2 2.1v0.2q0 1.2-0.9 2-0.8 0.8-1.9 1.1h-1.4q-1.2-0.2-2.1-1.1-0.8-0.9-0.8-2 0-0.1 0-0.3 0.1-0.1 0.1-0.2 0 0 0-0.1 0 0 0 0 0-0.1 0-0.1 0-0.1 0-0.1 0.3-0.9 1-1.4 0.8-0.6 1.6-0.8 0.1 0 0.2 0 0.1-0.1 0.2-0.1 0 0 0.1 0zm-0.1 0.9q0 0-0.1 0-0.6 0.1-1.1 0.5-0.6 0.4-0.7 1 0 0.1 0 0.1 0 0.1-0.1 0.1 0 0.2 0 0.3 0 0.1 0 0.3 0 0.5 0.2 1 0.2 0.5 0.7 0.9 0.3 0.1 0.7 0.2 0.3 0.1 0.6 0.2 0.1 0 0.2 0 0 0 0.1 0 0 0 0 0 0.1 0 0.1 0 0.6-0.1 1.2-0.4 0.6-0.3 0.9-0.9 0.1-0.1 0.1-0.2 0-0.1 0.1-0.2 0-0.1 0-0.3 0-0.1 0-0.3 0 0 0 0 0 0 0 0 0-0.8-0.4-1.4-0.5-0.7-1.3-0.8 0-0.1 0-0.1 0 0-0.1 0-0.1 0-0.1 0-0.1 0-0.2 0-0.1 0-0.2 0-0.1 0-0.2 0h-0.2q-0.1 0-0.2 0z"
                            />
                            <path
                                fillRule="evenodd"
                                className="s0"
                                d="m130.2 72.5q0.7 0.1 1.2 0.6 0.6 0.5 0.6 1.2 0 0 0 0.1 0 0.1 0 0.1 0 0.1 0 0.2-0.1 0-0.1 0.1-0.2 0.5-0.6 0.8-0.4 0.2-1 0.4l1.6 2.4 0.3 0.1v0.1h-1.7v-0.1l0.3-0.1-1.5-2.4h-2.2v2.4l0.3 0.1v0.1h-1.5l0.2-0.2v-5.7h-0.2v-0.2zm-3.1 2.7h2.8q0.4 0 0.8-0.3 0.3-0.3 0.3-0.6 0-0.1 0-0.2-0.1 0-0.1-0.1 0-0.1-0.1-0.2 0 0-0.1-0.1-0.1-0.1-0.3-0.2-0.2-0.1-0.4-0.1h-2.9z"
                            />
                            <path
                                fillRule="evenodd"
                                className="s0"
                                d="m137.4 72.5v0.2h-0.1q0 0 0 0 0 0-0.1 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1-0.1 0.1 0 0 0 0h0.1l2.6 5.5q0 0 0 0 0 0 0 0 0.1 0.1 0.1 0.1 0 0 0.1 0.1 0 0 0 0 0 0 0 0 0.1 0 0.1 0 0 0 0 0 0 0 0 0 0.1 0 0.1 0v0.1h-1.6q0-0.1 0-0.1 0.1 0 0.1 0 0 0 0 0 0.1 0 0.1-0.1v-0.1l-0.6-1.2h-3.3l-0.6 1.2v0.2h0.2v0.1h-1.4v-0.1h0.2l2.6-5.7q0 0 0 0 0 0-0.1-0.1 0 0 0 0 0 0 0 0 0 0 0 0-0.1 0-0.1 0 0 0 0 0 0 0-0.1 0v-0.2zm-2.1 3.7h2.5l-1.3-2.6z"
                            />
                        </g>
                    </g>
                </svg>
            </Link>
        </StyledLogo>
    )
}

export default Logo
