import ImageReveal from 'src/components/atoms/ImageReveal/ImageReveal'
import RichText from 'src/components/atoms/RichText/RichText'
import Text from 'src/components/atoms/Text/Text'
import Container from 'src/components/_layout/Container/Container'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import { CmsImage } from 'src/types'
import { chunk } from 'src/utils'
import { StyledCard, StyledChunk, StyledDescription } from './CardGroup.style'

type Card = {
    image: CmsImage
    title: string
    subtitle: string
    description: string
}

type CardGroupProps = {
    cards: Array<Card>
}
const CardGroup: React.FC<CardGroupProps> = ({ cards }) => {
    const chunks = chunk<Card>(cards, Math.ceil(cards.length / 2))
    return (
        <Container>
            <FlexWrapper
                direction={{ '@initial': 'column', '@md': 'row' }}
                justify="between"
                css={{ flexWrap: 'wrap' }}
            >
                {chunks.map((cardChunk, j) => (
                    <StyledChunk
                        as={Padder}
                        key={j}
                        top={{ '@initial': 'md', '@md': 'xl' }}
                    >
                        {cardChunk.map(
                            (
                                { image, title, subtitle, description },
                                index
                            ) => (
                                <div key={index}>
                                    <Padder top="md">
                                        <ImageReveal
                                            src={image.url}
                                            alt={image.alt}
                                        />
                                        <Padder top="xs">
                                            <Text
                                                as="h4"
                                                textVariant="heading3Bis"
                                            >
                                                {title}
                                            </Text>
                                            <Text textVariant="base2">
                                                {subtitle}
                                            </Text>
                                        </Padder>
                                        <RichText
                                            htmlString={description}
                                            textVariant="base"
                                            css={{
                                                strong: {
                                                    fontFamily: '$plain',
                                                    fontWeight: '$bold',
                                                },
                                            }}
                                        />
                                    </Padder>
                                </div>
                            )
                        )}
                    </StyledChunk>
                ))}
            </FlexWrapper>
        </Container>
    )
}

export default CardGroup
