import { FaMapMarkerAlt, FaPhoneAlt, FaRegEnvelope } from 'react-icons/fa'
import RichText from 'src/components/atoms/RichText/RichText'
import Text from 'src/components/atoms/Text/Text'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import useLabel from 'src/hooks/useLabel'
import { StyledContactLine } from './Contacts.style'

export type ContactsProps = {
    address: string
    telephone: string
    email: string
}

const Contacts: React.FC<ContactsProps> = ({ address, telephone, email }) => {
    const label = useLabel()
    return (
        <Padder top="sm">
            <StyledContactLine>
                <FlexWrapper as={Padder} left="sm" align="center">
                    <FaMapMarkerAlt color="#465346" fontSize="1.8rem" />
                    <RichText htmlString={address} />
                </FlexWrapper>
            </StyledContactLine>
            <StyledContactLine>
                <FlexWrapper as={Padder} left="sm" align="center">
                    <FaPhoneAlt color="#465346" fontSize="1.8rem" />
                    <Text textVariant="base">
                        {label('telephone')}
                        <br />
                        {telephone}
                    </Text>
                </FlexWrapper>
            </StyledContactLine>
            <StyledContactLine>
                <FlexWrapper as={Padder} left="sm" align="center">
                    <FaRegEnvelope color="#465346" fontSize="1.8rem" />
                    <Text textVariant="base">
                        {label('email')}
                        <br />
                        {email}
                    </Text>
                </FlexWrapper>
            </StyledContactLine>
        </Padder>
    )
}

export default Contacts
