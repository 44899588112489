import Button from 'src/components/atoms/Button/Button'
import {
    StyledDatePicker,
    StyledInput,
} from 'src/components/atoms/Input/Input.style'
import { styled } from 'src/styles/stitches.config'

export const StyledSideBooking = styled('div', {
    '@md': {
        width: 'calc(7 * $colWidth)',
        justifySelf: 'flex-end',
    },

    '@mdDown': {
        marginBottom: '$md',
    },

    [`${StyledInput} input`]: {
        backgroundColor: '#f3f3f3',
    },

    [`${Button}`]: {
        transform: 'translateY(50%)',
        minHeight: '80px',
    },

    '--select-background': '#f3f3f3',

    svg: {
        stroke: '$green!important',
    },
    '.react-date-picker': {
        backgroundColor: '#f3f3f3',
        paddingLeft: '$xxs',
    },
    [`${StyledDatePicker}`]: {
        paddingLeft: 0,
        marginTop: '$xxs',
    },
})

export const StyledSideBookingContent = styled('div', {
    padding: '$colWidth $colWidth 0',
    backgroundColor: '$white',
})
