import { styled } from 'src/styles/stitches.config'

export const StyledImageLaunch = styled('div', {
    variants: {
        padded: {
            true: {
                '@xl': {
                    paddingBottom: '$xxl',
                    backgroundImage:
                        'linear-gradient(to bottom, $whiteSmoke calc(100% - var(--space-xl)),$white calc(100% - var(--space-xl)), $white 100%)',
                    '& + *': {
                        marginTop: '0!important',
                    },
                },
            },
        },
    },
})

export const StyledImageWrapper = styled('div', {
    width: '100%',
    paddingBottom: '$lg',
    '@lg': {
        width: 'calc(9 * $colWidth)',
    },
    variants: {
        padded: {
            true: {
                '@md': {
                    paddingBottom: 0,
                    transform: 'translateY(50%)',
                },
            },
        },
    },
})

export const StyledImageGrid = styled('div', {
    variants: {
        grid: {
            true: {
                display: 'grid',
                gridTemplateColumns: '37.5% 20%',
                gridTemplateRows: '35% 32% 30%',
            },
        },
    },
})

export const StyledVerticalImageWrapper = styled('div', {
    position: 'relative',
    zIndex: 1,

    variants: {
        grid: {
            true: {
                gridColumn: '2 / 4',
                gridRow: '1 / 3',
                ratio: {
                    w: 367,
                    h: 516,
                },
            },
            false: {
                img: {
                    width: '100%',
                },
            },
        },
    },
})

export const StyledHorizontalImageWrapper = styled('div', {
    position: 'relative',
    zIndex: 2,
    gridColumn: '1 / 3',
    gridRow: '2 / 4',
    ratio: {
        w: 2,
        h: 3,
    },
})

export const StyledDescription = styled('div', {
    '@md': {
        width: '80%',
    },
})

export const StyledContentWrapper = styled('div', {
    '@lg': {
        width: 'calc(10 * $colWidth)',
    },
    '@md': {
        paddingLeft: 'calc(2 * $colWidth)',
    },
    variants: {
        inverted: {
            true: {
                '@md': {
                    paddingLeft: '0',
                    paddingRight: 'calc(2 * $colWidth)',
                },
            },
        },
        grid: {
            true: {
                '@md': {
                    width: 'calc(12 * $colWidth)',
                },
            },
            false: {
                [`${StyledDescription}`]: {
                    width: '100%',
                },
            },
        },
    },
})
