import React, { MutableRefObject, useEffect, useRef } from 'react'
import {
    StyledBackgroundDivider,
    StyledBackgroundDividerContent,
} from './BackgroundDivider.style'

const BackgroundDivider: React.FC<
    React.PropsWithChildren<{
        overlap?: boolean
        removePadding?: boolean
        removeMargin?: boolean
    }>
> = ({ overlap, removePadding, removeMargin, children }) => {
    const ref = useRef() as MutableRefObject<HTMLDivElement>

    useEffect(() => {
        if (ref.current) {
            const $app = document.querySelector(
                '.scroll-content'
            ) as HTMLDivElement
            if ($app) {
                setTimeout(() => {
                    const full = $app.clientHeight
                    const percent = (ref.current.offsetTop * 100) / full
                    $app.style.background = `linear-gradient(to bottom, var(--colors-whiteSmoke) ${percent}%, white ${percent}%, white 100%)`
                }, 500)

                return () => {
                    $app.style.background = ''
                }
            }
        }
    }, [])

    return (
        <StyledBackgroundDivider
            ref={ref}
            overlap={overlap}
            removeMargin={removeMargin}
        >
            <StyledBackgroundDividerContent removePadding={removePadding}>
                {children}
            </StyledBackgroundDividerContent>
        </StyledBackgroundDivider>
    )
}

export default BackgroundDivider
