import { forwardRef } from 'react'
import Select, { ActionMeta } from 'react-select'
type SelectProps = {
    styles?: any
    defaultValue?: any
    onChange?: (newValue: any, actionMeta: ActionMeta<any>) => void
    maxLength?: number
    withZero?: boolean
}
const AdultsSelect = forwardRef<any, SelectProps>(
    ({ styles, defaultValue, onChange, maxLength = 2, withZero }, ref) => {
        const selectOptions = Array(maxLength)
            .fill(0)
            .map((_, index) => ({
                label: `${index + 1}`,
                value: `${index + 1}`,
            }))

        const zeroOption = withZero ? { label: '0', value: '0' } : undefined
        return (
            <Select
                ref={ref}
                isSearchable={false}
                options={[zeroOption, ...selectOptions].filter(
                    (data) => !!data
                )}
                defaultValue={defaultValue}
                styles={styles}
                onChange={onChange}
            />
        )
    }
)

export default AdultsSelect
