import { useSelector } from 'react-redux'
import { getSettings } from 'src/selectors/settings'
import {
    StyledLanguageSwitcher,
    StyledSecondLang,
} from './LanguageSwitcher.style'
import flags from '../../atoms/_flags'
import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
const LanguageSwitcher: React.FC = (props) => {
    const [isVisible, setIsVisible] = useState<boolean>(false)
    const { language, languages } = useSelector(getSettings)
    /*@ts-ignore */
    const CurrentFlag = flags[language]
    const SecondFlag = language === 'it' ? flags.en : flags.it
    return (
        <StyledLanguageSwitcher>
            <button
                type="button"
                onClick={() => {
                    setIsVisible((prevState) => !prevState)
                }}
            >
                <CurrentFlag />
            </button>
            <AnimatePresence>
                {isVisible && (
                    <StyledSecondLang
                        as={motion.div}
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                    >
                        <a href={language === 'it' ? '/en' : '/it'}>
                            <SecondFlag />
                        </a>
                    </StyledSecondLang>
                )}
            </AnimatePresence>
        </StyledLanguageSwitcher>
    )
}

export default LanguageSwitcher
