import Text from '../Text/Text'
import { StyledRichText } from './RichText.style'
import type * as Stitches from '@stitches/react'

type RichTextProps = {
    htmlString: string
    textVariant?: string
    fontWeight?: string
    align?: string
    className?: string
    css?: Stitches.CSS
}
const RichText: React.FC<RichTextProps> = ({
    htmlString,
    textVariant = 'base',
    fontWeight = '$normal',
    align = 'left',
    css = {},
    className,
}) => {
    return (
        //@ts-ignore
        <Text
            textVariant={textVariant}
            css={{ fontWeight, ...css }}
            align={align}
            className={className}
        >
            <StyledRichText dangerouslySetInnerHTML={{ __html: htmlString }} />
        </Text>
    )
}

export default RichText
