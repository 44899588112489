import { styled } from 'src/styles/stitches.config'

const RadioCheckWrapper = styled('label', {
    position: 'relative',
    fontFamily: '$plain',
    a: {
        textDecoration: 'underline',
    },
    'input[type="radio"], input[type="checkbox"]': {
        position: 'absolute',
        visibility: 'hidden',
        opacity: 0,
        '+ span': {
            paddingLeft: '30px',
            boxSizing: 'border-box',
            cursor: 'pointer',
        },
        '+ span::before': {
            position: 'absolute',
            top: '-0.3rem',
            left: 0,
            width: '1.5rem',
            height: '1.5rem',
            border: 'solid 1px #aaa',
            backgroundColor: '#fff',
            content: '',
            boxSizing: 'border-box',
            borderRadius: '50%',
        },
        '&:checked + span::after': {
            position: 'absolute',
            top: '0.05rem',
            left: '0.375rem',
            width: '0.75rem',
            height: '0.75rem',
            backgroundColor: '#000',
            content: '',
            boxSizing: 'border-box',
            borderRadius: '50%',
        },
    },
})

export default RadioCheckWrapper
